<template>
  <div class="reg-form">
    <form class="reg-form__form" @submit.prevent="register">
      <div
        v-if="landingTemplate && landingTemplate.login_form_type !== 'surname'"
        class="reg-form__ctrl"
      >
        <input
          v-model="email"
          class="reg-form__input"
          type="email"
          :placeholder="$t('Registration.email')"
        />
        <span class="reg-form__span" :class="{ 'error-reg': emailError }">
          {{ emailError }}
        </span>
      </div>

      <div
        v-if="
          landingTemplate &&
            (landingTemplate.login_form_type === 'standard' ||
              landingTemplate.login_form_type === 'email') &&
            !standardLoginWithGeneratePassword
        "
        class="reg-form__ctrl"
      >
        <input
          v-model="fio"
          class="reg-form__input"
          type="text"
          :placeholder="$t('Registration.fio')"
        />
        <span class="reg-form__span" :class="{ 'error-reg': fioError }">
          {{ fioError }}
        </span>
      </div>

      <div
        v-if="landingTemplate.login_form_type === 'standard' && !standardLoginWithGeneratePassword"
        class="reg-form__ctrl"
      >
        <input
          v-model="password"
          class="reg-form__input"
          type="password"
          :placeholder="$t('Registration.password')"
        />
        <span class="reg-form__span" :class="{ 'error-reg': passwordError }">
          {{ passwordError }}
        </span>
      </div>

      <div
        v-if="landingTemplate.login_form_type === 'standard' && !standardLoginWithGeneratePassword"
        class="reg-form__ctrl"
      >
        <input
          v-model="repassword"
          class="reg-form__input"
          type="password"
          :placeholder="$t('Registration.repeatPassword')"
        />
        <span class="reg-form__span" :class="{ 'error-reg': repasswordError }">
          {{ repasswordError }}
        </span>
      </div>

      <div
        v-if="landingTemplate && landingTemplate.login_form_type === 'surname'"
        class="reg-form__ctrl"
      >
        <input
          v-model="firstName"
          class="reg-form__input"
          type="text"
          :placeholder="$t('Login.name')"
        />
        <span class="reg-form__span" :class="{ 'error-reg': emailError }">
          {{ emailError }}
        </span>
      </div>

      <div
        v-if="landingTemplate && landingTemplate.login_form_type === 'surname'"
        class="reg-form__ctrl"
      >
        <input
          v-model="secondName"
          class="reg-form__input"
          type="text"
          :placeholder="$t('Login.secondName')"
        />
        <span class="reg-form__span" :class="{ 'error-reg': fioError }">
          {{ fioError }}
        </span>
      </div>

      <div v-if="landingTemplate && landingTemplate.options.personal_data" class="reg-form__ctrl">
        <label for="processingPersonData" class="checkboxProcessingPersonData">
          <input id="processingPersonData" v-model="processingPersonData" type="checkbox" />
          <span
            >{{ $t("Login.processingPersonData") }}
            <router-link class="link-to-pda" target="_blank" to="/personal-data-agreement">
              {{ $t("Login.processingPersonDataLink") }}
            </router-link>
          </span>
        </label>
        <span :class="processingPersonDataStatus ? 'reg-form_error err' : 'reg-form_error'">
          {{ processingPersonDataStatus }}
        </span>
      </div>
      <auth-button filled type="submit" :disabled="disabled">
        {{ $t("Registration.registration") }}
      </auth-button>
    </form>
    <auth-button @click="$router.push('login')">
      {{ $t("Registration.login") }}
    </auth-button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { auth, landing, room } from "@/store/modules/store.namespaces";
import { LOGIN_BY_SURNAME, REGISTRATION, LOGOUT } from "@/store/modules/auth/action-types";
import { ENTER_TO_ROOM } from "@/store/modules/common/action-types";
import { wsErrorType } from "@/utils/errors";
import AuthButton from "@/components/common/auth/AuthButton";

/**
 * @param len
 */
function password_generator(len) {
  const length = len || 10;
  const string = "abcdefghijklmnopqrstuvwxyz"; // to upper
  const numeric = "0123456789";
  let password = "";
  let character = "";
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    let hold = string.charAt(entity1);
    hold = entity1 % 2 == 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    password = character;
  }
  return password;
}

export default {
  name: "ViewAuthRegistration",
  components: {
    AuthButton,
  },
  data() {
    return {
      email: "",
      fio: "",
      password: "",
      repassword: "",
      emailError: "",
      fioError: "",
      passwordError: "",
      repasswordError: "",
      processingPersonData: null,
      processingPersonDataStatus: "",
      firstName: "",
      firstNameError: "",
      secondName: "",
      secondNameError: "",
    };
  },
  computed: {
    ...mapState(landing, {
      landingTemplate: "template",
    }),
    validateMask() {
      return (
        this.landingTemplate.mask &&
        this.landingTemplate.mask.length &&
        this.email.indexOf(this.landingTemplate.mask) < 1
      );
    },
    disabled() {
      let disabled = false;
      const hasPersonalDataCheck =
        this.landingTemplate && this.landingTemplate.options.personal_data;

      if (this.landingTemplate && this.landingTemplate.login_form_type === "surname") {
        const firstName = this.firstName.toLocaleLowerCase().trim();
        const secondName = this.secondName.toLocaleLowerCase().trim();
        disabled = !firstName.length || !secondName.length;
      }

      return hasPersonalDataCheck ? disabled || !this.processingPersonData : disabled;
    },
    standardLoginWithGeneratePassword() {
      return (
        this.landingTemplate.login_form_type === "standard" &&
        this.landingTemplate.options &&
        this.landingTemplate.options.generate_password_signup
      );
    },
  },
  methods: {
    ...mapActions(auth, {
      loginBySurname: LOGIN_BY_SURNAME,
      registerUser: REGISTRATION,
      logout: LOGOUT,
    }),
    ...mapActions(room, {
      enterToRoom: ENTER_TO_ROOM,
    }),
    async register() {
      if (this.clearAllErrors()) {
        const domain = window.location.hostname.substring(0, window.location.hostname.indexOf("."));
        const firstName = this.firstName.toLocaleLowerCase().trim();
        const secondName = this.secondName.toLocaleLowerCase().trim();
        const login = `${domain}_${secondName}_${firstName}`;

        try {
          if (this.landingTemplate.login_form_type === "surname") {
            await this.loginBySurname({
              login,
              pass: "111111",
              fio: `${this.firstName} ${this.secondName}`,
            });
          } else if (this.landingTemplate.login_form_type === "email") {
            await this.registerUser({
              fio: this.fio,
              email: this.email,
              password: 111111,
              repassword: 111111,
              loginOnComplete: true,
            });
          } else {
            if (this.standardLoginWithGeneratePassword) {
              this.fio = this.fio || "Гость";
            }
            await this.registerUser({
              fio: this.fio,
              email: this.email,
              password: this.password,
              repassword: this.repassword,
              loginOnComplete: !this.standardLoginWithGeneratePassword,
            });
          }
          if (!this.standardLoginWithGeneratePassword) {
            await this.enterToRoom({ room: "default" });
          } else {
            this.logout();
          }
        } catch (error) {
          if (error.hasType && error.hasType(wsErrorType.AUTH_ERROR_FAIL_LOGIN_BY_SURNAME)) {
            this.logout();
          } else {
            const isRegisterErrorBySurName =
              error.hasType && error.hasType(wsErrorType.REGISTER_ERROR_FAIL_BY_SURNAME);
            this.emailError = this.$t(
              `Registration.${isRegisterErrorBySurName ? "fioExistError" : "emailExistError"}`,
            );
          }
        }
      }
    },
    validateEmail(email) {
      // eslint-disable-next-line max-len
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearAllErrors() {
      let check = true;
      this.emailError = "";
      this.passwordError = "";
      this.fioError = "";
      this.passwordError = "";
      this.repasswordError = "";
      this.processingPersonDataStatus = "";
      this.firstNameError = "";
      this.secondNameError = "";

      switch (this.landingTemplate.login_form_type) {
        case "surname":
          if (!this.firstName.length) {
            this.fioError = this.$t("Registration.fioError");
            check = false;
          }
          if (!this.secondName.length) {
            this.passwordError = this.$t("Registration.passwordError");
            check = false;
          }
          break;
        case "email":
          if (!this.validateEmail(this.email)) {
            this.emailError = this.$t("Registration.emailError");
            check = false;
          }

          if (this.validateMask) {
            this.emailError = this.$t("Registration.emailFormat");
            check = false;
          }

          if (!this.fio) {
            this.fioError = this.$t("common.required");
            check = false;
          }
          break;
        default:
          if (this.landingTemplate.options.generate_password_signup) {
            const pass = password_generator(4);
            this.repassword = pass;
            this.password = pass;
          }

          if (!this.validateEmail(this.email)) {
            this.emailError = this.$t("Registration.emailError");
            check = false;
          }

          if (this.validateMask) {
            this.emailError = this.$t("Registration.emailError");
            check = false;
          }

          if (this.fio.length < 4 && !this.standardLoginWithGeneratePassword) {
            this.fioError = this.$t("Registration.fioError");
            check = false;
          }
          if (this.password.length < 4) {
            this.passwordError = this.$t("Registration.passwordError");
            check = false;
          }
          if (this.password !== this.repassword) {
            this.repasswordError = this.$t("Registration.rePasswordError");
            check = false;
          }
          if (
            this.landingTemplate &&
            this.landingTemplate.options.personal_data &&
            !this.processingPersonData
          ) {
            this.processingPersonDataStatus = this.$t("Registration.processingPersonData");
            check = false;
          }
      }

      return check;
    },
  },
};
</script>

<style lang="less" scoped>
@ws-grey: #414141;
@ws-fs1: 16px;
@ws-lh1: normal;

.link-to-pda {
  color: #07a5dc;
}

.reg-form {
  margin-bottom: 10px;
  color: var(--login-button-color);

  .checkboxProcessingPersonData {
    display: flex;
    margin-top: 5px;
    align-items: flex-start;
    input {
      margin: 0 3px 0 0;
    }
    span {
      font-size: 10px;
      color: var(--login-button-color);
    }
  }

  input[type="password"],
  input[type="text"],
  input[type="email"] {
    width: 100%;
    padding: 10px 2px;
    color: inherit;
    font-size: @ws-fs1;
    line-height: @ws-lh1;
    border: none;
    border-bottom: 1px solid var(--base-text-color);
    background: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  button.button.big {
    margin-top: 15px;
    width: 100%;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: var(--base-color);
    a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  a.button.bottom {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: var(--base-color);
  }

  .reg-form {
    &__form {
      margin-bottom: 15px;
    }

    &__ctrl {
      margin-bottom: 5px;
    }

    &__input {
      display: block;
      font-size: 16px;
      width: 100%;
      border: none;
      background: transparent;
      padding: 10px 2px;
      outline: none;
      //border-bottom: 1px solid var(--base-text-color);

      &:focus + span::before,
      &:hover + span::before {
        background: var(--base-color);
      }
    }

    &__span {
      display: block;
      font-size: 10px;
      color: var(--negative-color);
      height: 13px;

      &.error-reg:before {
        background: var(--negative-color);
      }
    }

    &.btn-submit {
      margin-bottom: 10px;
    }
    .registration-btn-sbt {
      margin-top: 40px;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 640px) {
  .reg {
    position: relative;
    margin: 0 auto;
    #select-lang-login-form {
      padding-top: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
